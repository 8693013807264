import React, { Component } from "react";

import "../../css/Styles.css";

class HaaseBio extends Component {
  render() {
    return (
      <div>
        <h2>Bio</h2>
        <p>
          <strong>Accomplishments:</strong> RAAM veteran and several time top
          American finisher.
        </p>
        <p>
          <strong>Adventure:</strong> Ultracycling
        </p>
        <p>
          <strong>Country:</strong> USA
        </p>
        <p>
          <strong>Profession:</strong> Bike Shop Owner
        </p>
      </div>
    );
  }
}

export default HaaseBio;
