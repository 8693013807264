import React, { Component } from "react";

import "../../css/Styles.css";

class MacDonaldBio extends Component {
  render() {
    return (
      <div>
        <h2>Bio</h2>
        <p>
          <strong>Accomplishments: </strong>
          World record holder, Only Scottish Solo RAAM finisher.
        </p>
        <p>
          <strong>Sport:</strong> Cycling
        </p>
        <p>
          <strong>Country:</strong> Scotland
        </p>
        <p>
          <strong>Profession:</strong> Technology
        </p>
      </div>
    );
  }
}

export default MacDonaldBio;
