/*
    Prelimary types of actions for our store
    Not yet confirmed
    TODO:
        Reconfigure to correct actions
        Add more actions as nesscary
*/
export const GET_ITEMS = "GET_ITEMS";
export const ADD_ITEMS = "ADD_ITEMS";
export const DELETE_ITEMS = "DELETE_ITEMS";
export const ITEMS_LOADING = "ITEMS_LOADING";
