import React, { Component } from "react";

import "../../css/Styles.css";

class HeringBio extends Component {
  render() {
    return (
      <div>
        <h2>Bio</h2>
        <p>
          <strong>Favorite Phrase:</strong> "Love life and don't take anything
          for granted."
        </p>
        <p>
          <strong>Adventure:</strong> Snowshoe Running
        </p>
        <p>
          <strong>Country:</strong> USA
        </p>
        <p>
          <strong>Profession:</strong> Professional Triathlete, Coach, Race
          Director
        </p>
      </div>
    );
  }
}

export default HeringBio;
