import React, { Component } from "react";

import "../../css/Styles.css";

class CrossBio extends Component {
  render() {
    return (
      <div>
        <h2>Bio</h2>
        <p>
          <strong>Accomplishments:</strong> 2020 Jr Iditarod 2nd Place
        </p>
        <p>
          <strong>Adventure:</strong> Dogsledding
        </p>
        <p>
          <strong>Country:</strong> Canada
        </p>
        <p>
          <strong>Profession:</strong> Student
        </p>
      </div>
    );
  }
}

export default CrossBio;
