import React, { Component } from "react";

import "../../css/Styles.css";

class FraissardBio extends Component {
  render() {
    return (
      <div>
        <h2>Bio</h2>
        <p>
          <strong>Totem:</strong> The Fox
        </p>
        <p>
          <strong>Adventure:</strong> Journey Around Rwanda
        </p>
        <p>
          <strong>Country:</strong> France
        </p>
        <p>
          <strong>Sport:</strong> Gravel Cycling
        </p>
      </div>
    );
  }
}

export default FraissardBio;
