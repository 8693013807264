import React, { Component } from "react";

import "../css/Styles.css";

class Footer extends Component {
  render() {
    return <footer className="Footer">&copy; It's Your Move - 2021</footer>;
  }
}

export default Footer;
