import React, { Component } from "react";

import "../../css/Styles.css";

class SamarthBio extends Component {
  render() {
    return (
      <div>
        <h2>Bio</h2>
        <p>
          Dr. Amit kicked off his <strong>6,000 km</strong> journey on February
          15th in Mumbai. He will pedal an average of{" "}
          <strong>20 hours a day</strong> until his journey is complete.
        </p>
        <p>
          <strong>Adventure:</strong> Ride Across India
        </p>
        <p>
          <strong>Country:</strong> India
        </p>
        <p>
          <strong>Favorite Phrase:</strong> "Be your own hero"
        </p>
      </div>
    );
  }
}

export default SamarthBio;
